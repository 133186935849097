.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.form-control:focus {
  outline: none; /* Прибирає стандартну обводку */
  box-shadow: none; /* Прибирає тінь при фокусі */
  border: 2px solid;
  border-color: var(--success-color);
}
.form-control::placeholder {
  color: #202123;
}
.modal-content {
  background: #202123;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  position: relative;
  flex-direction: column;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px;
  border-color: var(--success-color);
  border-radius: 4px;
}

.btn-auth {
  width: 100%;
  margin-top: 1rem;
}

.toggle-auth {
  margin-top: 1rem;
  text-align: center;
}

.toggle-auth span {
  color: var(--success-color);
  cursor: pointer;
  text-decoration: underline;
}
.input-wrapper {
  padding: 5px;
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.success-message {
  color: green;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #28a745;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup-message button {
  background-color: white;
  color: #28a745;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.popup-message button:hover {
  background-color: #f1f1f1;
}
.logout-icon {
  margin-right: 0.3rem;
}
